import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0205eeac"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "color red" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = ["data-point"]
const _hoisted_4 = { class: "content" }
const _hoisted_5 = { class: "color blue" }
const _hoisted_6 = { class: "header" }
const _hoisted_7 = ["data-point"]
const _hoisted_8 = { class: "content" }
const _hoisted_9 = {
  key: 0,
  class: "result"
}
const _hoisted_10 = ["data-winner"]
const _hoisted_11 = ["data-sub"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["d-multitable-card-result-root", { reverse: _ctx.gameType === 1 }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", {
          class: "points",
          "data-point": _ctx.redTotal
        }, _toDisplayString(_ctx.redLabel), 9, _hoisted_3)
      ]),
      _createElementVNode("div", _hoisted_4, [
        (_ctx.redThirdCard !== '')
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["card3", _ctx.redThirdCard])
            }, null, 2))
          : _createCommentVNode("", true),
        (_ctx.redSecondCard !== '')
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: _normalizeClass(["card2", _ctx.redSecondCard])
            }, null, 2))
          : _createCommentVNode("", true),
        (_ctx.redFirstCard !== '')
          ? (_openBlock(), _createElementBlock("div", {
              key: 2,
              class: _normalizeClass(["card1", _ctx.redFirstCard])
            }, null, 2))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", {
          class: "points",
          "data-point": _ctx.blueTotal
        }, _toDisplayString(_ctx.blueLabel), 9, _hoisted_7)
      ]),
      _createElementVNode("div", _hoisted_8, [
        (_ctx.blueFirstCard !== '')
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["card1", _ctx.blueFirstCard])
            }, null, 2))
          : _createCommentVNode("", true),
        (_ctx.blueSecondCard !== '')
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: _normalizeClass(["card2", _ctx.blueSecondCard])
            }, null, 2))
          : _createCommentVNode("", true),
        (_ctx.blueThirdCard !== '')
          ? (_openBlock(), _createElementBlock("div", {
              key: 2,
              class: _normalizeClass(["card3", _ctx.blueThirdCard])
            }, null, 2))
          : _createCommentVNode("", true)
      ])
    ]),
    (_ctx.showWinnerLabel)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createElementVNode("div", {
            class: "winner",
            "data-winner": _ctx.winner
          }, null, 8, _hoisted_10),
          (_ctx.winner !== 2 && _ctx.winner !== 5)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "sub-label",
                "data-sub": _ctx.winner
              }, null, 8, _hoisted_11))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ], 2))
}