
import { RESULT } from '@/types/global'
import audioPlayer, { SOUNDKEY } from '@/utils/sounds'
import {
    computed,
    defineComponent,
    onMounted,
    PropType,
    ref,
    TableData
} from 'vue'

export default defineComponent({
    emits: ['on-game-result'],
    props: {
        gameType: {
            type: Number as PropType<number>,
            required: true
        },
        mData: {
            type: Object as PropType<TableData>,
            required: false
        },
        showWinnerLabel: {
            type: Boolean as PropType<boolean>,
            default: () => true
        },
        playSound: {
            type: Boolean as PropType<boolean>,
            default: () => false
        }
    },
    setup(props, { emit }) {
        const redTotal = ref<number>(0)
        const blueTotal = ref<number>(0)
        const winner = ref<number>(-1) // 3 player 1 banker  2 tie

        const blueHand = ref<string[]>([])
        const redHand = ref<string[]>([])
        const resultLabel = ref<string>('')

        const winnerColor = ref(-1)

        onMounted(() => {
            if (props.mData) {
                const { mData, gameType } = props
                blueHand.value = []
                redHand.value = []
                redTotal.value = mData.expand.bankerCount ?? 0
                blueTotal.value = mData.expand.playerCount ?? 0
                winner.value = mData.expand.whoWin

                winnerColor.value = winner.value

                if (winnerColor.value === 5) winnerColor.value = 2

                // return r
                resultLabel.value = ''

                const results: number[] = []

                if (winner.value === 1 && gameType === 1) {
                    resultLabel.value = '庄 赢'
                    results.push(0)
                } else if (winner.value === 4 && gameType === 2) {
                    resultLabel.value = '龙 赢'
                    results.push(4)
                } else if (winner.value === 2 || winner.value === 5) {
                    resultLabel.value = '和 赢'
                    if (gameType === 1) results.push(2)
                    else results.push(3)
                } else if (winner.value === 3 && gameType === 1) {
                    resultLabel.value = '闲 赢'
                    results.push(1)
                } else if (winner.value === 6 && gameType === 2) {
                    resultLabel.value = '虎 赢'
                    results.push(5)
                }

                if (mData.expand.playerOne)
                    blueHand.value.push(mData.expand.playerOne)
                if (mData.expand.playerTwo)
                    blueHand.value.push(mData.expand.playerTwo)
                if (mData.expand.playerThree)
                    blueHand.value.push(mData.expand.playerThree)

                if (mData.expand.bankerOne)
                    redHand.value.push(mData.expand.bankerOne)
                if (mData.expand.bankerTwo)
                    redHand.value.push(mData.expand.bankerTwo)
                if (mData.expand.bankerThree)
                    redHand.value.push(mData.expand.bankerThree)
                const { length } = mData.expand.resultList
                if (length > 0) {
                    const lastResult = mData.expand.resultList[length - 1]
                    let bluePair = false
                    let redpair = false
                    if (lastResult > 10) {
                        let pair = lastResult % 10
                        if (pair === 3) {
                            redpair = true
                            bluePair = true
                            results.push(6)
                            results.push(7)
                        } else if (pair === 2) {
                            bluePair = true
                            results.push(6)
                        } else if (pair === 1) {
                            redpair = true
                            results.push(7)
                        }
                    }

                    if (props.playSound) {
                        if (winner.value === RESULT.RED) {
                            if (bluePair && redpair) {
                                audioPlayer.Play(SOUNDKEY.BANKER_WIN_BOTH_PAIR)
                            } else if (bluePair && !redpair) {
                                audioPlayer.Play(
                                    SOUNDKEY.BANKER_WIN_PLAYER_PAIR
                                )
                            } else if (!bluePair && redpair) {
                                audioPlayer.Play(
                                    SOUNDKEY.BANKER_WIN_BANKER_PAIR
                                )
                            } else {
                                audioPlayer.Play(SOUNDKEY.BANKER_WIN)
                            }
                        } else if (winner.value === RESULT.BLUE) {
                            if (bluePair && redpair) {
                                audioPlayer.Play(SOUNDKEY.PLAYER_WIN_BOTH_PAIR)
                            } else if (bluePair && !redpair) {
                                audioPlayer.Play(
                                    SOUNDKEY.PLAYER_WIN_PLAYER_PAIR
                                )
                            } else if (!bluePair && redpair) {
                                audioPlayer.Play(
                                    SOUNDKEY.PLAYER_WIN_BANKER_PAIR
                                )
                            } else {
                                audioPlayer.Play(SOUNDKEY.PLAYER_WIN)
                            }
                        } else if (winner.value === RESULT.GREEN) {
                            // play sound tie
                            if (bluePair && redpair) {
                                audioPlayer.Play(SOUNDKEY.TIE_BOTH_PAIR)
                            } else if (bluePair && !redpair) {
                                audioPlayer.Play(SOUNDKEY.TIE_PLAYER_PAIR)
                            } else if (!bluePair && redpair) {
                                audioPlayer.Play(SOUNDKEY.TIE_BANKER_PAIR)
                            } else {
                                audioPlayer.Play(SOUNDKEY.TIE)
                            }
                        }
                    }
                }

                emit('on-game-result', results)
            }
        })

        const redLabel = computed(() => {
            if (props.gameType === 1) return '庄'
            else if (props.gameType === 2) return '龙'
            return ''
        })

        const blueLabel = computed(() => {
            if (props.gameType === 1) return '闲'
            else if (props.gameType === 2) return '虎'
            return ''
        })

        const redFirstCard = computed(() => {
            if (redHand.value.length > 0) {
                return `v_${redHand.value[0]}`
            }
            return ''
        })

        const redSecondCard = computed(() => {
            if (redHand.value.length > 1) {
                return `v_${redHand.value[1]}`
            }
            return ''
        })

        const redThirdCard = computed(() => {
            if (redHand.value.length > 2) {
                return `v_${redHand.value[2]}`
            }
            return ''
        })

        const blueFirstCard = computed(() => {
            if (blueHand.value.length > 0) {
                return `v_${blueHand.value[0]}`
            }
            return ''
        })

        const blueSecondCard = computed(() => {
            if (blueHand.value.length > 1) {
                return `v_${blueHand.value[1]}`
            }
            return ''
        })

        const blueThirdCard = computed(() => {
            if (blueHand.value.length > 2) {
                return `v_${blueHand.value[2]}`
            }
            return ''
        })

        return {
            // cards
            blueThirdCard,
            blueSecondCard,
            blueFirstCard,
            redThirdCard,
            redSecondCard,
            redFirstCard,
            redLabel,
            blueLabel,
            resultLabel,
            redTotal,
            blueTotal,
            winner
        }
    }
})
